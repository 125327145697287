import * as React from "react";
import { graphql } from "gatsby";

const noPage = () => {
  <h1>404</h1>;
};

export default noPage;

// export const Head = () => <title>404</title>;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
